import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EmpresaService } from './_services/empresa.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass'
})
export class AppComponent {
  title = 'spa_iregistro';

  constructor(private empresaService: EmpresaService) {

  }

  addEmpresa() {
    debugger;
    let empresa = {
      "Id": "",
      "idEmpresa": 569645,
      "razonSocial": "Empresa Felipe Espinoza",
      "email": "felipe.espinoza@iconstruye.com",
      "telefono": "+569584145665",
      "direccion": "Alcantara 20, las condes"
    }
    this.empresaService.addEmpresa(empresa).subscribe(o => {
      console.log('ok')
    });

  }
}
