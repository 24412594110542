import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }

  addEmpresa(empresa: any) : Observable<void> {
    return this.http.post(`${environment.urlApi}api/v1/empresas`, empresa)
      .pipe(map(data => {
        console.log('ok');
      }));
  }
}
